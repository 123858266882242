import { ethers } from 'ethers'
import { getMulticallContract1, getMulticallContract2, getMulticallContract3 } from 'utils/contractHelpers'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface MulticallOptions {
  requireSuccess?: boolean
}

export const multicall1 = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract1()
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  const { returnData } = await multi.aggregate(calldata)

  const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))

  return res as any
}

export const multicall2 = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract2()
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  const { returnData } = await multi.aggregate(calldata)

  const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))

  return res as any
}

export const multicall3 = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract3()
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  const { returnData } = await multi.aggregate(calldata)

  const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))

  return res as any
}

/**
 * Multicall V2 uses the new "tryAggregate" function. It is different in 2 ways
 *
 * 1. If "requireSuccess" is false multicall will not bail out if one of the calls fails
 * 2. The return includes a boolean whether the call was successful e.g. [wasSuccessful, callResult]
 */
// export const multicallv2 = async <T = any>(
//   abi: any[],
//   calls: Call[],
//   options: MulticallOptions = { requireSuccess: true },
// ): Promise<T> => {
//   const { requireSuccess } = options
//   const multi = getMulticallContract()
//   const itf = new ethers.utils.Interface(abi)

//   const calldata = calls.map((call) => ({
//     target: call.address.toLowerCase(),
//     callData: itf.encodeFunctionData(call.name, call.params),
//   }))
//   const returnData = await multi.tryAggregate(requireSuccess, calldata)
//   const res = returnData.map((call: any, i: number) => {
//     const [result, data] = call
//     return result ? itf.decodeFunctionResult(calls[i].name, data) : null
//   })

//   return res as any
// }
