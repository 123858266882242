const contracts = {
  nft: { // NFT
    97: '0x8f251FBFf8e3b5FE0093ae7DFFCE82Ea513B775e',//'0xD41d1b9072E4918d617dB6e7BF8D30FD77dec586',
    56: '0x5CF4890E08f50Dbc80A8A67FadbD5517922B2C37',
    5: '0x53bB02b226E619504F010Fb65651e3718c9C9D0f',
    4: '0xd753294Cc2F2be848C1cfAAffceEB2F4d7899B1f',
    1: '0x1fc6B3f9225b84111c7F5aEF0291b01A35b15731',
  },
  staking: { // NFT Staking
    97: '0x704698E3571C29083d8B5595939b0D8e193fb9D9',//'0x028105B7B930427B3c4D84519e1d6Dac465b4c3D',
    56: '0x36000E261Ad3fD443bc557020130FE09f905d9cb',
    5: '0x9797c9cd77D41a7F894600e83886c92CB2C2F49F',
    4: '0x0E6D4f98eD5549bd3570fb7E59Bf6Bea8Fe246F4',
    1: '0xcAfB5890A55cF9F315E4B865C190F1c3b5ab6854',
  },
  reward: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x13f3C824A8eb681c17BE9D1ffDDe7A3D35fEaF84',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  character: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x16E0abD9B5634Ba8E5e43bFF29653a202425C405',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  clothes: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x20e711264f3b32690560f9095ef218F4334fA8F6',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  weapon: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x4e5A367952fbE7411C93D71f3F338037146Bc363',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  battlePass: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x05AB2d94B7329818AFA28177Cfe9b4604220294e',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  origin: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0x6b03F942A3d7dfEE10C5D015c097755936C67E17',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  marketplace: { // Reward Token
    97: '0x6e58aEFCbA76a6BCa4695264380BD589a3e838B3',//'0xb59A7147291248bdAaC5Bb754c702f5e18588A67',
    56: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    5: '0xe3A8Cf676c22512C4A96BeBf154c791d3debEf67',
    4: '0x032DAeAcf1Dbe5D72e6Fe5ECe055b7b4C4577c8F',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  multicall1: {
    97: '0x60194b3eDF9b95A6087FE1940275AE7036641dd8',
    56: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    5: '0xdb6F81301a14f6060B46f4ea4EB2748C543A2408',
    4: '0x477975206744De34bE580AA5Efb4576011E8d232',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
  },
  multicall2: {
    97: '0xC67F4B3fe070A5F18c519a5c70819017D01021f4',
    56: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    5: '0xdb6F81301a14f6060B46f4ea4EB2748C543A2408',
    4: '0x477975206744De34bE580AA5Efb4576011E8d232',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
  },
  multicall3: {
    97: '0x4cbb8205923E455f4659462C59eb44417BEF57F6',
    56: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    5: '0xdb6F81301a14f6060B46f4ea4EB2748C543A2408',
    4: '0x477975206744De34bE580AA5Efb4576011E8d232',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
  },
  register: {
    97: '0x92e9D7Da7F781E1B6FdA00feD0bD33B73B446667',//'0x1C85321c96FF240A89F6724CA4943a90383Ae634',
    56: '0xeCbd00f8274Af7b0023075CB1633bE750e6d6c06',
    5: '0xBdF6522d451f0000076f44dc72ebAcCD37aE3373',
    4: '0xea6E393474a1DBdC4422D3c4Aed6B77E47A4B585',
    1: '0xeCbd00f8274Af7b0023075CB1633bE750e6d6c06',
  },
  pancakeRouter: {
    97: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    5: '0xd753294Cc2F2be848C1cfAAffceEB2F4d7899B1f',
    4: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
    1: '0xeCbd00f8274Af7b0023075CB1633bE750e6d6c06',
  },
  WBMB: {
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    5: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    1: '0xeCbd00f8274Af7b0023075CB1633bE750e6d6c06',
  },
  USDT: {
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    5: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    1: '0xeCbd00f8274Af7b0023075CB1633bE750e6d6c06',
  },
  PRICE_FEED: {
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    5: '0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e',
    4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    1: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  },
}

export default contracts